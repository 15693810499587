<template>
  <div class="text-center justify-content-center">
    <div :class="props.class" class="lista">
      <div v-if="titulo" class="row">
        <h1>{{ titulo }}</h1>
      </div>

      <div v-if="subtitulo" class="row mt-3">
        <h3>{{ subtitulo }}</h3>
      </div>

      <div class="row mt-5 justify-content-center">
        <div
          v-for="item in itens"
          :key="item?.id"
          class="mb-5"
          :class="colunasRef"
        >
          <CustomCartao
            :class="item?.class"
            :titulo="item?.titulo"
            :imagem="item?.imagem"
            :local="item?.local"
            :texto="item?.texto"
            :subtitulo="item?.subtitulo"
            :botao="item?.botao"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  class: String,
  titulo: String,
  subtitulo: String,
  itens: Array,
  colunas: String,
  fixa: { type: Boolean, default: false },
  width: String,
  height: String,
});
const colunasRef = ref("col-lg-4");

onMounted(() => {
  let col = 4;
  let colunas = Number(props.colunas) || 0;

  if (props.fixa && props.itens.length <= 6) {
    col = props.itens.length == 5 ? 2 : 12 / props.itens.length;
  } else if (colunas) {
    col = colunas == "5" ? 2 : 12 / colunas;
  } else if (props.itens.length <= 3) {
    col = 12 / props.itens.length;
  } else {
    col = 4;
  }

  colunasRef.value = `col-md-${col}`;
});
</script>

<script>
export default {
  name: "CustomLista",
};
</script>
